import { ready } from './lib/utils';

import { programDepartment } from './modules/programDepartment';
import { slider } from './modules/slider';
import { tabs } from './modules/tabs';

const high = {
	init() {
		programDepartment.init();
		slider.init();
		tabs.init();
	}
};

ready(function () {
	high.init();
});
