const programDepartment = {

	$window: $(window),

	elem: {
		$button: $('.program-department__items .btn')
	},

	init() {
		if (this.elem.$button.length > 0) {
			this.bindUIActions();
		}
	},

	bindUIActions() {

		this.elem.$button.on('mouseenter focus', function() {
			let id = $(this).attr('id').substr(5);
			$(this).closest('.program-department').find('.program-department__background--active').removeClass('program-department__background--active');
			$(this).closest('.program-department').find('#background--' + id).addClass('program-department__background--active');
		});

		this.elem.$button.on('mouseleave blur', function() {
			$(this).closest('.program-department').find('.program-department__background--active').removeClass('program-department__background--active');
			$(this).closest('.program-department').find('#background--default').addClass('program-department__background--active');
		});

	}

};

export { programDepartment };
